@import "./utils.scss";
@import "./reset.scss";

/* Uncomment for use Flickity style 
--------------------------------------------- */
// @import "./vendors/flickity.css";

body {
  position: relative;
  z-index: 0;
  width: 100%;
  height: auto;
  overflow-x: hidden;
  font-size: rem(16) !important;
  line-height: 1 !important;
  color: $black !important;
  @include font_content_regular();
}

// #__next {
//   overflow-x: hidden;
// }

h1,
h2,
h3,
h4,
h5 {
  //font-weight: $bold;
  @include font_primary();
}

h1 {
  font-size: rem(64);
  @include font_title_bold();
}

h2 {
  font-size: rem(40);
  @include font_title_semi_bold();
}

h3 {
  margin-bottom: rem(20);
  font-size: rem(34);
  @include font_title_medium();
}

h4 {
  font-size: rem(28);
  @include font_title_regular();
}

h5 {
  font-size: rem(22);
  @include font_title_regular();
}

p,
span,
li {
  line-height: 1.5;
}

ul,
ol {
  li {
    ul,
    ol {
      margin-left: rem(20);
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

strong {
  @include font_content_bold();
}

em {
  @include font_content_italic();
}
