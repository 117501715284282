// RESET

* {
  box-sizing: border-box;
}

html {
  color: #000000;
  background: #ffffff;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
  padding: 0;
  margin: 0;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
fieldset,
img {
  border: 0;
}
address,
caption,
cite,
code,
dfn,
th,
var {
  font-style: normal;
  font-weight: 400;
}
em {
  font-style: italic;
  font-weight: 400;
}
ul {
  //list-style: none;
  list-style-position: inside;
}
ol {
  list-style: decimal;
  list-style-position: inside;
}

a {
  color: $black;
  text-decoration: none;
  cursor: pointer;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: 400;
}
q:before,
q:after {
  content: "";
}
abbr,
acronym {
  font-variant: normal;
  border: 0;
}
sub {
  vertical-align: text-bottom;
}
figure {
  margin: 0;
}
input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;

  font-weight: inherit;
}

input {
  border-radius: 0;
  appearance: none;
}
legend {
  color: #000000;
}

button {
  display: inline-block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  background-color: $white;
  border: none;
}

input,
label,
select,
button,
textarea {
  display: inline-block;
  padding: 0;
  margin: 0;

  /* Browsers have different default form fonts */
  line-height: 1;
  //white-space: normal;
  vertical-align: middle;
  background: none;
  border: 0;
  //border-bottom: 1px solid $white;
}

/* Remove the stupid outer glow in Webkit */
input:focus {
  outline: 0;

  &::placeholder {
    opacity: 0.6;
    transition: 0.3s;
  }
}

/* Box Sizing Reset
-----------------------------------------------*/

/* All of our custom controls should be what we expect them to be */
input,
textarea {
  box-sizing: content-box;
}

/* These elements are usually rendered a certain way by the browser */
button,
input[type="reset"],
input[type="button"],
input[type="submit"],
input[type="checkbox"],
input[type="radio"],
select {
  box-sizing: border-box;
}

/* Text Inputs
-----------------------------------------------*/

input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
  &::placeholder {
    color: $white;
  }
}

/* Button Controls
-----------------------------------------------*/

input[type="checkbox"],
input[type="radio"] {
  width: 13px;
  height: 13px;
}

/* File Uploads
-----------------------------------------------*/

input[type="file"] {
  //empty
}

/* Search Input
-----------------------------------------------*/

/* Make webkit render the search input like a normal text field */
input[type="search"] {
  appearance: textfield;
  box-sizing: content-box;
}

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
  display: none;
}

/* Buttons
-----------------------------------------------*/

button,
input[type="reset"],
input[type="button"],
input[type="submit"] {
  width: auto;
  /* Fix IE7 display bug */
  overflow: visible;
}

/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button {
  padding: 0;
  background: none;
  border: 0;
}

/* Textarea
-----------------------------------------------*/

textarea {
  /* Turn off scroll bars in IE unless needed */
  overflow: auto;
  /* Move the label to the top */
  vertical-align: top;
}

/* Selects
-----------------------------------------------*/

select[multiple] {
  /* Move the label to the top */
  vertical-align: top;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px $black inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: $white !important;
}
