@import "./../styles/utils.scss";

.change-rotation-container {
  position: absolute;
  z-index: 1000000;
  background-color: #ffffff27;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.change-rotation-icon-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 5rem;
}
.change-rotation-label {
  margin-top: 4rem;
  font-size: 2rem;
  font-weight: 600 !important;
  @include dialog_font_Regular;
}

.phone {
  height: 50px;
  width: 100px;
  border: 3px solid #ffff;
  border-radius: 10px;
  animation: rotate 1.5s ease-in-out infinite alternate;
  /* display: none; */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}
