/* Titles
--------------------------------------------- */

@font-face {
  font-family: "KleeOne-SemiBold";
  src: url("../../public/fonts/KleeOne-SemiBold.ttf");
  // src: url("/fonts/BarlowCondensed-Bold.woff") format("woff"),
  //   url("/fonts/BarlowCondensed-Bold.otf") format("opentype");
  font-weight: 900;
}
@mixin font_title_black() {
  font-family: "KleeOne-SemiBold";
  font-weight: 900;
}

@font-face {
  font-family: "KleeOne-SemiBold";
  src: url("../../public/fonts/KleeOne-SemiBold.ttf");
  // src: url("/fonts/Montserrat-Bold.woff") format("woff"),
  //   url("/fonts/Montserrat-Bold.otf") format("opentype");
  font-weight: 700;
}
@mixin font_title_bold() {
  font-family: "KleeOne-SemiBold";
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../public/fonts/Montserrat-SemiBold.ttf") format("truetype");
  // src: url("/fonts/Montserrat-SemiBold.woff") format("woff"),
  //   url("/fonts/Montserrat-SemiBold.otf") format("opentype");
  font-weight: 600;
}
@mixin font_title_semi_bold() {
  font-family: "Montserrat-SemiBold", sans-serif;
  font-weight: 600;
}

@font-face {
  font-family: "KleeOne-SemiBold";
  src: url("../../public/fonts/KleeOne-SemiBold.ttf");
  // src: url("/fonts/Montserrat-Medium.woff") format("woff"),
  //   url("/fonts/Montserrat-Medium.otf") format("opentype");
  font-weight: 500;
}
@mixin font_title_medium() {
  font-family: "KleeOne-SemiBold";
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("../../public/fonts/Montserrat-Regular.ttf") format("truetype");
  // src: url("/fonts/Montserrat-Regular.woff") format("woff"),
  //   url("/fonts/Montserrat-Regular.otf") format("opentype");
  font-weight: 400;
}
@mixin font_title_regular() {
  font-family: "Montserrat-Regular", sans-serif;
  font-weight: 400;
}

@mixin font_primary() {
  @include font_title_bold();
}

/* Content
--------------------------------------------- */
@font-face {
  font-family: "Lato-Black";
  src: url("../../public/fonts/Lato-Black.ttf") format("truetype");
  // src: url("/fonts/Lato-Black.woff") format("woff"),
  //   url("/fonts/Lato-Black.otf") format("opentype");
  font-weight: 800;
}
@mixin font_content_black() {
  font-family: "Lato-Black", sans-serif;
  font-weight: 800;
}

@font-face {
  font-family: "Lato-Bold";
  src: url("../../public/fonts/Lato-Bold.ttf") format("truetype");
  // src: url("/fonts/Lato-Bold.woff") format("woff"),
  //   url("/fonts/Lato-Bold.otf") format("opentype");
  font-weight: 700;
}
@mixin font_content_bold() {
  font-family: "Lato-Bold", sans-serif;
  font-weight: 700;
}

@font-face {
  font-family: "Lato-Regular";
  src: url("../../public/fonts/Lato-Regular.ttf") format("truetype");
  // src: url("/fonts/Lato-Regular.woff") format("woff"),
  //   url("/fonts/Lato-Regular.otf") format("opentype");
  font-weight: 400;
}
@mixin font_content_regular() {
  font-family: "Lato-Regular", sans-serif !important;
  font-weight: 400 !important;
}

@font-face {
  font-family: "Lato-Italic";
  src: url("../../public/fonts/Lato-Italic.ttf") format("truetype");
  // src: url("/fonts/Lato-Italic.woff") format("woff"),
  //   url("/fonts/Lato-Italic.otf") format("opentype");
  font-weight: 400;
}
@mixin font_content_italic() {
  font-family: "Lato-Italic", sans-serif;
  font-weight: 400;
}

@font-face {
  font-family: "Lato-Light";
  src: url("../../public/fonts/Lato-Light.ttf") format("truetype");
  // src: url("/fonts/Lato-Light.woff") format("woff"),
  //   url("/fonts/Lato-Light.otf") format("opentype");
  font-weight: 300;
}
@mixin font_content_light() {
  font-family: "Lato-Light", sans-serif;
  font-weight: 300;
}

@mixin font_secondary() {
  @include font_content_regular();
}

@font-face {
  font-family: "KleeOne-SemiBold";
  src: url("../../public/fonts/KleeOne-SemiBold.ttf");
}

@mixin dialog_font_semiBold() {
  font-family: "KleeOne-SemiBold", sans-serif;
  font-weight: 600;
}

@font-face {
  font-family: "KleeOne-Regular";
  src: url("../../public/fonts/KleeOne-Regular.ttf");
}

@mixin dialog_font_Regular() {
  font-family: "KleeOne-Regular", sans-serif;
  font-weight: 600;
}

@font-face {
  font-family: "BebasNeue-Regular";
  src: url("../../public/fonts/BebasNeue-Regular.ttf");
}

@mixin character_font() {
  font-family: "BebasNeue-Regular", sans-serif;
  font-weight: 400;
}
