@import "./../styles/utils.scss";

.splashscreen,
.mobileScreen {
  background-image: url("../../public/img/splashscreen.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  position: relative;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileScreen {
  background-image: url("../../public/img/mobileSplashscreen.png");
}

.splashscreen-btn,
.linkToMuseum,
.restartTour {
  margin-bottom: 10rem;
  transition: all 5 0ms;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-repeat: no-repeat;
}
.finalSceneBtnContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  bottom: 0;
}

.splashscreen-btn {
  background-repeat: no-repeat;
  background-image: url("../../public/img/composants/je-visite.png");
  width: 300px;
  height: 10rem;
  top: 85%;
  left: 50%;
  bottom: 10vh;
  position: absolute;
}

.mobileScreen-btn {
  position: absolute;
  bottom: 10%;
  height: 10rem;
}
.mobileScreen-btn-landscape {
  position: absolute;
  bottom: 2%;
  left: 24%;
  width: 50%;
}

.splashscreen-btn:hover {
  background-image: url("../../public/img/composants/je-visite-hover.png");
}

.linkToMuseum,
.restartTour {
  height: 10vh;
  position: absolute;
}

.linkToMuseum,
.linkToMuseum:hover,
.restartTour,
.restartTour:hover {
  background-position: center;
  background-size: contain;
}
.linkToMuseum {
  background-image: url("../../public/img/composants/linkToMuseum.png");
  width: 35vw;
  left: 30%;
}
.linkToMuseum:hover {
  background-image: url("../../public/img/composants//linkToMuseumHover.png");
}

.restartTour {
  left: 70%;
  margin-bottom: 0;
  width: 25vw;

  top: 50%;
  background-image: url("../../public/img/composants/restartTourHover.png");
}
.restartTour:hover {
  background-image: url("../../public/img/composants/restartTour.png");
}

.pano {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.pano canvas {
  left: 0;
  top: 0;
}

.info-hotspot {
  min-width: 50vw;
}
.tutoriel-hotspot {
  & p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
  }
  & h3 {
    text-align: center;
  }
  & .hotspot-tutoriel-first-text {
    margin: 1rem 0 1rem 2vw;
    & ::before {
      content: "";
      background-image: url("../../public/img/icons/nextSceneArrow.png");
      display: inline-block;
      height: 50px;
      min-width: 50px;
      background-size: cover;
      background-position: center;
      margin-right: 25px;
    }
  }
  & .hotspot-tutoriel-second-text {
    margin-left: 2vw;
    & ::before {
      content: "";
      height: 50px;
      min-width: 80px;
      position: relative;
      /* margin-right: 25px; */
      background-image: url("../../public/img/icons/arrow-left-right-hand.svg");
      background-size: cover;
      background-position: center;
      /* background-position: -6px 4px; */
      background-repeat: no-repeat;
      right: 15px;
      top: 13px;
    }
  }
}

.hotspot-info-container {
  padding: 4rem !important;
  height: auto;
}

.link {
  background-color: transparent !important;
  border: none !important;
}
.link-hotspot-container {
  height: 100px;
  width: 100px;
}

.hotspot-info-container,
.readmore-main-container,
.change-rotation-icon-container {
  border: 4px solid #e0c280;
  border-radius: 18px;
  @include dialog_font_Regular;
  background-color: rgba(60, 51, 36, 0.94);
  color: $white;
}

.readmore-main-container {
  padding: 1rem 2rem;
  min-height: 14rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.readmore-main-container p {
  font-size: 18px;
  line-height: 26px;
}

.readmore-button-container {
  display: flex;
  justify-content: center;
}

.close-icon-container {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
}

.hotspot-main-container h3 {
  font-size: 36px;
  line-height: 52px;
  margin: 0;
  width: 100%;
  padding-bottom: 2rem;
}

.hotspot-main-container p {
  @include dialog_font_Regular;
  font-size: 18px;
  line-height: 26px;
}
.readmore-btn {
  position: relative;
  bottom: 2.2rem;
}

.icon-btn {
  width: 70px;
  height: 70px;
}
.dialog-container {
  position: fixed;
  left: 5%;
  bottom: 2%;
}

.dialog-paragraph,
.dialog-button {
  display: flex;
  align-items: center;
}

.dialog-paragraph {
  font-size: 24px !important;
  justify-content: flex-start;
  position: relative;
  padding: 1rem;
}

.dialog-button {
  justify-content: center;
}

.character-illustration {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -90px;
  margin: auto 0;
  height: 187px;
}

.hide {
  display: none !important;
}
.helperArrowContainer {
  .helperArrow {
    width: 90px;
  }
}

.final-scene-container {
  position: relative;
  top: -25%;
  width: 50vw;
  p {
    font-size: 20px;
  }
}

@media screen and (max-width: 1500px) {
  .dialog-container {
    max-width: 706px !important;
    left: 25%;
    bottom: 10%;
  }
  .character-illustration {
    height: 110px;
    left: -70px;
  }

  .hotspot-info-container {
    padding: 1rem !important;
    left: 20vw;
  }
  .final-scene-container {
    top: -30%;
    left: 0;
  }

  .restartTour {
    position: absolute;
    left: 50%;
    top: 60%;
  }

  .linkToMuseum {
    background-image: url("../../public/img/composants/linkToMuseum.png");
    left: 50%;
    top: 48%;
    width: 70vw;
  }

  .restartTour {
    width: 60vw;
    left: 50%;
    background-image: url("../../public/img/composants/restartTourHover.png");
  }
  .final-dialog {
    left: 6vw;
  }

  .linkToMuseum,
  .restartTour {
    margin-bottom: 0;
  }
  .info-hotspot {
    min-width: 450px;
  }
}
